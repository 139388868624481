import React from "react";

import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { graphql } from "gatsby";

import "../../styles/pages/developer.scss";

import Header from "../../components/Header";
import SEO from "../../components/seo";
import PostListVerbose from "../../components/PostListVerbose";
import { ISEOProps } from "../../types/common";

const seoData: ISEOProps = {
  title: "Developer Story of Qus",
  description:
    "쿠스의 개발 이야기 - 개발하면서 기억하고 싶은 것들, 함께 나누고 싶은 이야기들을 기록합니다.",
  // imageUrl: "main/main-space-ship-light.png", TODO: 추가
};

const Container = ({ data }) => {
  const {
    allMarkdownRemark: { edges },
  } = data;

  return (
    <Header theme="developer">
      <SEO {...seoData} />
      <div className="content-body-developer">
        <div className="intro">
          <p>FrontEnd 개발자입니다.</p>
          <p>
            하나의 언어로 많은 것들을 할 수 있는 JavaScript의 범용성을 사랑하고
            <br />
            JavaScript의 결점을 보완해주는 TypeScript를 좋아합니다.
          </p>
          <p>
            요즘은 특히 JAMStack을 이용한 웹 어플리케이션 구축, ReactNative를
            통한 앱개발, ServerLess 아키텍쳐에 관심을 갖고 있습니다.
          </p>
          <p>스타트업, 개발 문화와 업무 프로세스에도 관심이 많습니다.</p>

          <p>
            좋은 제품을 만드는데 관심이 많아 개인적으로도 몇 가지 어플리케이션을
            개발&운영하고 있습니다.
            <a href="https://qustart.com/" target="_blank">
              소개 페이지
              <i className="las la-external-link-alt"></i>
            </a>
          </p>
        </div>

        <div className="resume">
          <div className="label">이력서 Resume</div>

          <div className="button-container">
            <a className="web" href="/developer/resume" target="_blank">
              <i className="las la-desktop"></i> Web
            </a>
            <a
              className="download"
              href="/[변형준]Resume-2021.03.27.pdf"
              download
            >
              <i className="las la-download"></i> Download
            </a>
          </div>
        </div>

        <div className="sns">
          <a
            href="https://github.com/qus1225"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="la la-github"></i>
          </a>

          <a
            href="https://www.linkedin.com/in/hyeongjun-byeon-327392142/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="lab la-linkedin"></i>
          </a>
        </div>

        <PostListVerbose theme="developer" title="최신글" postList={edges} />
      </div>
    </Header>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 3
      sort: { fields: fields___prefix, order: DESC }
      filter: { fields: { theme: { eq: "developer" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            thumbNail {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            series
            theme
            prefix(formatString: "YYYY.MM.DD")
          }
          excerpt
        }
      }
    }
  }
`;

export default Container;
